// @flow
import { finect as theme } from '@finect/tabular-themes/finect';
// import { PrivateRoute } from '@finect/front-core/Routes';
import { contents, products } from './../configs';

// SSR paths
import {
  FundsList,
  FundSheet,
  FundSheetById,
  ETFsHome,
  PlansHome,
  FundsHome,
  FundsCategories,
  FundsCategory,
  FundsManagementCompanies,
  FundsManagementCompany,
  FundsComparison,
  ProductSheet,
  ProductQuestions,
  ProductReviews,
  ProductReview,
  WriteReview,
  ArticleScroll,
  Groups,
  MultimediaCommons,
  Latest,
  Panel,
  Storefront,
  StorefrontProductsList,
  DigitalHome,
  DigitalAcademy,
  AlternativesHome,
  AlternativesAcademy,
  Favorites,
  Feed,
  Bank,
  BankArticle,
  BankProducts,
  BankArticleList,
  Tag,
  TagsHome,
  Home,
  OnBoarding,
  ProductSubscription,
  SubscriptionConditions,
  UserProfileLayout,
  Group
} from './';

const base = '';
const iicRoutes = [
  {
    path: theme.routes.FundsList,
    exact: true,
    components: [FundsList]
  },
  {
    path: `${base}/${theme.partner.iic.slugs.fund}`,
    exact: true,
    components: [FundsHome]
  },
  {
    path: `${base}/${theme.partner.iic.slugs.etf}`,
    exact: true,
    components: [ETFsHome]
  },
  {
    path: `${base}/${theme.partner.iic.slugs.plan}`,
    exact: true,
    components: [PlansHome]
  },
  {
    path: theme.partner.routes.FundsComparison,
    exact: true,
    components: [FundsComparison]
  },
  {
    path: theme.partner.routes.FundsCategories,
    exact: true,
    components: [FundsCategories]
  },
  {
    path: theme.partner.routes.FundsCategoriesTab,
    exact: true,
    components: [FundsCategories]
  },
  {
    path: theme.partner.routes.FundsCategory,
    components: [FundsCategory],
    exact: true
  },
  {
    path: theme.partner.routes.FundsCategory.replace('-:alias', ''),
    components: [FundsCategory],
    exact: true
  },
  {
    path: theme.partner.routes.FundsManagementCompanies,
    components: [FundsManagementCompanies],
    exact: true
  },
  {
    path: theme.partner.routes.FundsManagementCompany,
    components: [FundsManagementCompany],
    exact: true
  },
  {
    path: theme.partner.routes.FundsManagementCompany.replace('-:alias', ''),
    components: [FundsManagementCompany],
    exact: true
  },
  {
    path: theme.partner.routes.FundSheet,
    exact: true,
    components: [FundSheet]
  },
  {
    path: theme.partner.routes.FundSheetById,
    exact: true,
    components: [FundSheetById]
  }
];

export const routes = [
  ...iicRoutes,

  // Portada / contenidos
  {
    path: '/',
    exact: true,
    components: [Home]
  },
  {
    path: products.routes.sheet,
    exact: true,
    components: [ProductSheet]
  },
  {
    path: theme.partner.routes.FundQuestions,
    exact: true,
    components: [ProductQuestions]
  },
  {
    path: theme.partner.routes.ProductQuestions,
    exact: true,
    components: [ProductQuestions]
  },

  // Reviews
  {
    path: theme.partner.routes.ProductReviews,
    exact: true,
    components: [ProductReviews]
  },
  {
    path: theme.partner.routes.FundReviews,
    exact: true,
    components: [ProductReviews]
  },
  {
    path: contents.routes.contentReview,
    exact: true,
    components: [ProductReview]
  },
  {
    path: contents.routes.iicReview,
    exact: true,
    components: [ProductReview]
  },
  {
    path: theme.partner.routes.ProductWriteReview,
    exact: true,
    components: [WriteReview]
  },
  {
    path: theme.partner.routes.FundWriteReview,
    exact: true,
    components: [WriteReview]
  },
  // En esta línea estaban las rutas de IICs.
  {
    path: '/articulos/:article',
    exact: true,
    components: [ArticleScroll]
  },
  {
    path: '/:subtype(grupos|usuario)/:profile/articulos/:article',
    exact: true,
    components: [ArticleScroll]
  },
  {
    path: '/grupos',
    exact: true,
    components: [Groups]
  },
  {
    path: '/videos',
    exact: true,
    components: [MultimediaCommons],
    params: { type: 'video' }
  },
  {
    path: '/podcasts',
    exact: true,
    components: [MultimediaCommons],
    params: { type: 'podcast' }
  },
  {
    path: '/articulos-financieros/noticias',
    exact: true,
    components: [Latest],
    params: { type: 'noticias' }
  },
  {
    path: '/articulos-financieros/en-un-minuto',
    exact: true,
    components: [Latest],
    params: { type: 'en-un-minuto' }
  },
  {
    path: '/articulos-financieros/a-fondo',
    exact: true,
    components: [Latest],
    params: { type: 'a-fondo' }
  },
  {
    path: '/panel',
    exact: true,
    components: [Panel],
    omninavShadow: true
  },
  {
    path: '/(mis|tus)-finanzas',
    exact: true,
    components: [Panel],
    omninavShadow: true
  },
  {
    path: '/invertir/:tag',
    exact: true,
    components: [Storefront],
    omninavShadow: true
  },
  {
    path: '/invertir/:tag/listado',
    exact: true,
    components: [StorefrontProductsList],
    omninavShadow: true
  },
  {
    path: theme.partner.routes.DigitalHome,
    exact: true,
    components: [DigitalHome],
    omninavShadow: false
  },
  {
    path: `${theme.partner.routes.DigitalHome}/formacion`,
    exact: true,
    components: [DigitalAcademy],
    omninavShadow: false
  },
  {
    path: `${theme.partner.routes.AlternativesHome}`,
    exact: true,
    components: [AlternativesHome],
    omninavShadow: false
  },
  {
    path: `${theme.partner.routes.AlternativesHome}/formacion`,
    exact: true,
    components: [AlternativesAcademy],
    omninavShadow: false
  },
  {
    path: '/(mis|tus)-productos',
    exact: true,
    components: [Favorites]
  },
  {
    path: '/(mis|tus)-temas',
    exact: true,
    components: [Feed]
  },
  {
    path: '/bancos/:organization',
    exact: true,
    components: [Bank],
    omninavShadow: true
  },
  {
    path: '/bancos/:organization/articulos/:article',
    exact: true,
    components: [BankArticle],
    omninavShadow: true
  },
  {
    path: '/bancos/:organization/productos',
    exact: true,
    components: [BankProducts],
    omninavShadow: true
  },
  {
    path: '/bancos/:organization/productos/:typeSlug',
    exact: true,
    components: [BankProducts],
    omninavShadow: true
  },
  {
    path: '/bancos/:organization/analisis',
    exact: true,
    components: [BankArticleList],
    omninavShadow: true
  },
  {
    path: '/temas/:tag',
    exact: true,
    components: [Tag]
  },
  {
    path: '/temas',
    exact: true,
    components: [TagsHome]
  },
  {
    path: '/onboarding',
    exact: true,
    components: [OnBoarding]
  },
  {
    path: '/usuario/:alias',
    exact: true,
    components: [UserProfileLayout]
  },
  {
    path: '/usuario/:alias/:tab(respuestas|articulos|reviews)',
    exact: true,
    components: [UserProfileLayout]
  },
  {
    path: '/grupos/:profile',
    exact: true,
    components: [Group]
  },
  {
    path: '/grupos/:profile/:tab',
    exact: true,
    components: [Group]
  },
  {
    path: `/:productTypeSlug(servicios)/:productSlug(finect-subscription)/comprar`,
    exact: true,
    components: [ProductSubscription]
  },
  {
    path: `/:productTypeSlug(servicios)/:productSlug(finect-subscription)/condiciones`,
    exact: true,
    components: [SubscriptionConditions]
  }
];
