// @flow
import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { NotFound } from '@finect/tabular-components/NotFound';
import { PrivateRoute } from '@finect/front-core/Routes';
import { withTheme } from 'styled-components';

import {
  FundSheet,
  FundSheetById,
  Feed,
  Panel,
  ArticleScroll,
  Latest,
  Favorites,
  Groups,
  Storefront,
  StorefrontsHome,
  StorefrontProductsList,
  DigitalHome,
  DigitalAcademy,
  AlternativesHome,
  AlternativesAcademy,
  ProductSheet,
  ProductComparison,
  FundsHome,
  PlansHome,
  ETFsHome,
  FundsCategories,
  FundsCategory,
  FundsManagementCompanies,
  FundsManagementCompany,
  FundsComparison,
  FundsList,
  WriteReview,
  ProductReview,
  ProductReviews,
  Start,
  OnBoarding,
  MyComparisons,
  MyAuthorizations,

  //Supercomparador
  SuperComparison,

  //Multimedia
  MultimediaCommons,

  // Bancos
  BankArticle,
  BankArticleList,
  Bank,
  BankProducts,
  AdvisersFeedback,
  Tag,
  TagsHome,
  ProductQuestions,
  Home,
  Group,
  UserProfileLayout,
  // ProductCheckout,
  ProductSubscription,
  SubscriptionConditions
} from './scenes';

import { BanksSPA } from './scenes/Banks/components';

type Props = {
  location?: Object,
  theme: Object
};

class AppRouter extends React.Component<Props> {
  render() {
    const { routes } = this.props.theme.partner;

    return (
      <>
        <Switch>
          <Route path={routes.Bank} component={BanksSPA} />
        </Switch>

        <Switch>
          {/* Profiles */}
          <Route exact path={routes.GroupProfile} component={Group} />
          <Route exact path="/grupos/:profile/:tab" component={Group} />

          <Route exact path="/usuario/:alias/:tab(respuestas|articulos|reviews)" component={UserProfileLayout} />
          <Route exact path={routes.UserProfile} component={UserProfileLayout} />

          {/* Artículos */}
          <Route exact path={`/articulos/:article`} component={ArticleScroll} />
          <Route exact path="/:subtype(grupos|usuario)/:profile/articulos/:article" component={ArticleScroll} />
          <Route
            exact
            path="/articulos-financieros/noticias"
            render={props => <Latest type="noticias" {...props} />}
          />
          <Route exact path="/articulos-financieros/a-fondo" render={props => <Latest type="a-fondo" {...props} />} />
          <Route
            exact
            path="/articulos-financieros/en-un-minuto"
            render={props => <Latest type="en-un-minuto" {...props} />}
          />
          {/* Artículos - Redirects */}
          <Route exact path="/articulos-financieros">
            <Redirect to="/articulos-financieros/noticias" />
          </Route>
          <Route exact path="/articulos-financieros/ultimos">
            <Redirect to="/articulos-financieros/noticias" />
          </Route>

          {/* ············· old links to /blog/, /blogs/ or /blogs- */}
          <Route exact path="/blog/*">
            <Redirect to="/articulos-financieros/noticias" />
          </Route>
          <Route exact path="/blogs/*">
            <Redirect to="/articulos-financieros/noticias" />
          </Route>
          <Route exact path="/blogs-*">
            <Redirect to="/articulos-financieros/noticias" />
          </Route>

          {/* Bancos */}
          <Route exact path={routes.BankArticle} component={BankArticle} />
          <Route exact path={routes.BankProducts} component={BankProducts} />
          <Route exact path={`${routes.Bank}/productos`} component={BankProducts} />
          <Route exact path={routes.Bank} component={Bank} />
          <Route exact path={routes.BankArticleList} component={BankArticleList} />

          {/* Escaparates */}
          <Route exact path={routes.StorefrontsHome} component={StorefrontsHome} />
          <Route exact path={routes.Storefront} component={Storefront} />
          <Route exact path={routes.StorefrontProductsList} component={StorefrontProductsList} />
          {/* Activos digitales */}
          <Route exact path={routes.DigitalHome} component={DigitalHome} />
          <Route exact path={`${routes.DigitalHome}/formacion`} component={DigitalAcademy} />
          {/* Activos alternativos */}
          <Route exact path={routes.AlternativesHome} component={AlternativesHome} />
          <Route exact path={`${routes.AlternativesHome}/formacion`} component={AlternativesAcademy} />

          {/* Comparador de productos que no son IIC */}
          <Route exact path={routes.ProductsComparison} render={props => <ProductComparison {...props} />} />

          {/* Fichas de producto */}
          <Route exact path={routes.ProductSheet} component={ProductSheet} />

          {/* Listados de preguntas */}
          <Route exact path={routes.ProductQuestions} component={ProductQuestions} />
          <Route exact path={routes.FundQuestions} component={ProductQuestions} />

          {/* Listados de reviews */}
          <Route exact path={routes.ProductReviews} component={ProductReviews} />
          <Route exact path={routes.FundReviews} component={ProductReviews} />

          {/* Creación de reviews */}
          <Route exact path={routes.ProductWriteReview} render={props => <WriteReview {...props} />} />
          <Route exact path={routes.FundWriteReview} render={props => <WriteReview {...props} />} />

          {/* Detalle de review */}
          <Route exact path={routes.ProductReview} render={props => <ProductReview {...props} />} />
          <Route exact path={routes.FundReview} render={props => <ProductReview {...props} />} />

          {/* Grupos */}
          <Route exact path="/grupos" component={Groups} />

          {/* Multimedia */}
          <Route exact path="/videos" render={props => <MultimediaCommons type="video" {...props} />} />
          <Route exact path="/podcasts" render={props => <MultimediaCommons type="podcast" {...props} />} />

          {/* IICs */}
          <Route exact path={routes.FundsList} component={FundsList} />
          {/* IICs - Supercomparador (fondos-inversion) - Comparison (planes-pensiones, etfs) */}
          <Route exact path="/fondos-inversion/comparador" component={SuperComparison} />
          <Route exact path={routes.FundsComparison} component={FundsComparison} />

          {/* /categoria | /categoria/:tab llevan a FundCategories */}
          <Route exact path={routes.FundsCategories} component={FundsCategories} />
          <Route exact path={routes.FundsCategoriesTab} component={FundsCategories} />
          <Route exact path={routes.FundsCategory} component={FundsCategory} />
          {/* -- old routes.FundsCategory */}
          <Route exact path={routes.FundsCategory.replace('-:alias', '')} component={FundsCategory} />

          {/* Gestoras */}
          <Route exact path={routes.FundsManagementCompanies} component={FundsManagementCompanies} />
          <Route exact path={routes.FundsManagementCompany} component={FundsManagementCompany} />
          {/* -- old routes.FundsManagementCompany */}
          <Route exact path={routes.FundsManagementCompany.replace('-:alias', '')} component={FundsManagementCompany} />

          {/* Ficha de fondos */}
          <Route exact path={routes.FundSheet} component={FundSheet} />
          <Route exact path={routes.FundSheetById} component={FundSheetById} />

          {/* Homes de fondos de inversión y de planes de pensiones */}
          <Route exact path={routes.PlansHome} component={PlansHome} />
          <Route exact path={routes.ETFsHome} component={ETFsHome} />
          <Route exact path={routes.FundsHome} component={FundsHome} />

          {/* Home de Finect */}
          <Route exact path="/" component={Home} />

          {/* ············· Redirects home */}
          <Route exact path="/(pro|Home|home|homepage|index)" render={() => <Redirect to="/" />} />

          {/* Página de tags - Temas */}
          <Route exact path="/temas" component={TagsHome} />

          {/* Página de tag - Tema */}
          <Route exact path="/temas/:tag" render={props => <Tag {...props} />} />

          {/* Escenas privadas de usuario */}
          <PrivateRoute exact path="/(mis|tus)-temas" from="/tus-temas" component={Feed} />
          <PrivateRoute exact path="/(mis|tus)-favoritos" from="/tus-favoritos" component={Favorites} />
          <PrivateRoute exact path="/panel" from="/tus-finanzas" component={Panel} />
          {/* Redirects */}
          <Route exact path="/(mis|tus)-productos">
            <Redirect to={'/tus-favoritos'} />
          </Route>

          <PrivateRoute exact path="/(mis|tus)-finanzas" from="/tus-finanzas" component={Panel} />
          <PrivateRoute exact path="/(mis|tus)-finanzas/feed" from="/tus-finanzas/feed" component={Feed} />

          <PrivateRoute exact path="/tus-comparaciones" component={MyComparisons} />
          <PrivateRoute exact path="/tus-autorizaciones" component={MyAuthorizations} />

          {/* Escenas privadas de Onboarding  */}
          <Route exact path="/onboarding/start" component={Start} />
          <PrivateRoute exact path="/onboarding" component={OnBoarding} />

          {/* Valoración asesores */}
          <PrivateRoute exact path="/tus-finanzas/asesores/valoracion/:lead" component={AdvisersFeedback} />
          <PrivateRoute exact path="/tus-finanzas/asesores/valoracion" component={AdvisersFeedback} />

          {/* ProductSubscription */}
          <Route
            exact
            path={`/:productTypeSlug(servicios)/:productSlug(finect-subscription)/comprar`}
            component={ProductSubscription}
          />
          <Route
            exact
            path={`/:productTypeSlug(servicios)/:productSlug(finect-subscription)/condiciones`}
            component={SubscriptionConditions}
          />
          {/* ProductCheckout */}
          {/* <Route exact path={routes.ProductCheckout} component={ProductCheckout} /> */}

          <Route component={NotFound} />
        </Switch>
      </>
    );
  }
}

export default withTheme(AppRouter);
